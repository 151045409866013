import { SecurityContext } from '@lib';
import { navigate } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';

type SendRequestPassProps = {};

const RequestResetPassword: FC<SendRequestPassProps> = () => {
  const { resetPasswordControl } = useContext(SecurityContext);

  useEffect(() => {
    if (resetPasswordControl) {
      navigate('/');
      resetPasswordControl.open();
    }
  }, [resetPasswordControl]);

  return null;
};

export default RequestResetPassword;
